import { baseApi } from "api/base/base.api";
import { handleError } from "api/error/handle-error";

export interface SendVerifyCodePayload {
  countryCode: number;
  phoneNumber: string;
}

export interface VerifyCodePayload {
  placeId: string;
  codeId: string;
  code: string;
}

const BASE_PLACE_URL = "/v1/auth/place";
const PLACE_URL = {
  VERIFY_CODE: `${BASE_PLACE_URL}/send-verify-code`,
  SIGN_IN: `${BASE_PLACE_URL}/sign-in`,
};

export const sendVerifyCode = (payload: SendVerifyCodePayload) => {
  return baseApi.post<any>(PLACE_URL.VERIFY_CODE, payload).catch(handleError);
};

export const placeSignIn = (payload: VerifyCodePayload) => {
  return baseApi.post<any>(PLACE_URL.SIGN_IN, payload).catch(handleError);
};
